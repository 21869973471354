import React, { ReactElement, useContext } from "react";

import Moment from "react-moment";
import moment from "moment";

import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LocaleContext, LocalizedLabels } from "./locale-context";
import getAwardLabels from "../queries/get-award-labels";
import { ThemeFactory } from "@chweb/commonui";

type AwardIcon = {
  value: string;
  labels: LocalizedLabels;
};

function AwardIcon ({ value, labels }: AwardIcon): ReactElement {
  const MEDALS: {[key: string]: { color: string, labelId: string}} = {
    'gold': { color: 'gold', labelId: 'awardsMedalGold' },
    'silver': { color: 'silver', labelId: 'awardsMedalSilver' },
    'bronze': { color: '#b08d57', labelId: 'awardsMedalBronze' }
  };

  const lowerCaseValue = value.toLowerCase();
  if (lowerCaseValue in MEDALS) {
    const medal = MEDALS[lowerCaseValue];
    return <FontAwesomeIcon icon = { faMedal }
      className = "w3-xlarge"
      style = {{ color: medal.color }}
      title = { labels[medal.labelId] }
    />;
  } else if (value.match(/[0-9]+\/[0-9]/)) {
    const split = value.split('/');
    return <span><b>{ split[0] }</b>/{ split[1] }</span>;
  } else {
    return <span><b>{ value }</b></span>;
  }
}

export type AwardProps = {
  wine: {
    title: string;
    id: string;
  };
  vintage: number;
  source: string;
  grade: string;
  when?: moment.Moment;
  whenFormat?: string;
};

export function Award ({ wine, vintage, source, grade, when, whenFormat }: AwardProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const momentLocale = locale === 'zh' ? 'zh-cn' : locale;

  try {
    require(`moment/locale/${momentLocale}`);
  } catch (e) {}

  const dateString = when && <Moment locale = { momentLocale }
    format = { whenFormat }
    style = {{ textTransform: 'capitalize' }}
  >
    { when.toDate() }
  </Moment>;

  const theme = ThemeFactory.make(wine.id);
  const labels = getAwardLabels(locale);
  return (
    <div className = "w3-row-padding w3-padding-16">
      <div className = "w3-col l1">
        <AwardIcon value = { grade } labels = { labels } />
      </div>
      <div className = "w3-col l3">
        <div className = "w3-container w3-cell" style = {{ width: '160px' }}>
          <span className= { 'cptitletiny ' + theme.header() } title = { wine.title }>
            { wine.title }
          </span>
        </div>
        <div className = "w3-container w3-cell w3-stretch" title = { labels.awardsVintage }>
          <b>{ vintage }</b>
        </div>
      </div>
      <div className = "w3-col l8">
        <div className = "w3-container">
          <div>{ source }</div>
          <div
            className = "w3-small"
            style = {{ textIndent: '2em' }}>
            <span>{ labels.awardsPublishedLabel } </span> { dateString }
          </div>
        </div>
      </div>
    </div>);
}

