import { useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';

interface AwardDataRaw {
  source: string,
  awarded: string,
  vintage: number,
  grade: string,
  when: string
}

interface AwardsData {
  awards: {
    frontmatter: {
      awards: AwardDataRaw[]
    }
  }
}

interface AwardData {
  source: string,
  awarded: string,
  vintage: number,
  grade: string,
  when?: moment.Moment,
  whenFormat?: string
}

function convertToAwardData(data: AwardDataRaw): AwardData {
  const result: AwardData = {
    source: data.source,
    awarded: data.awarded,
    vintage: data.vintage,
    grade: data.grade
  };

  if (!data.when) {
    return result;
  }

  const dateParts = data.when.split(' ');
  let format = null;
  switch (dateParts.length) {
    case 1: format = 'YYYY'; break;
    case 2: format = 'MMMM YYYY'; break;
    case 3: format = 'DD MMMM YYYY'; break;
    default:
      format = null;
  }

  if (!format) {
    return result;
  }

  result.when = moment(data.when, format, 'en');
  result.whenFormat = format;
  return result;
}

function parseAwards (awards: AwardDataRaw[]): AwardData[] {
  const listOfAwards: AwardData[] = awards.map(a => convertToAwardData(a));

  // sorting the awards
  listOfAwards.sort((first, second) => {
    if (first.vintage !== second.vintage) {
      return second.vintage - first.vintage;
    } else {
      if (second.when && first.when) {
        return second.when.toDate().getTime() - first.when.toDate().getTime();
      }
      return 0;
    }
  });

  return listOfAwards;
}

function trimPerWine (awards: AwardData[], maxPerWine: number): AwardData[] {
  const counter: {[key: string]: number} = {};
  return awards.filter(a => {
    const wineId = a.awarded;
    if (!counter[wineId]) {
      counter[wineId] = 1;
    } else {
      counter[wineId]++;
    }
    return counter[wineId] <= maxPerWine;
  });
}

export default function getAwards (onlyWineId: string | null = null, maxPerWine: number | null = null) {
  const { awards }: AwardsData = useStaticQuery(
    graphql`{
      awards: markdownRemark(fields: {slug: {eq: "/awards/"}}) {
        frontmatter {
          awards {
            source
            awarded
            vintage
            grade
            when
          }
        }
      }
    }`
  );

  let result = parseAwards(awards.frontmatter.awards);
  if (onlyWineId) {
    result = result.filter(a => onlyWineId === a.awarded);
  }
  if (maxPerWine) {
    return trimPerWine(result, maxPerWine);
  }
  return result;
}
