export class WineTitleInfo {
    private readonly PREMIER_CRU_TOKEN = "premier";

    public longLabel: string;
    public shortLabel: string;
    public premierCruLabel: string | null;

    constructor(title: string) {
        const subtitleStartIndex = title.toLowerCase().indexOf(this.PREMIER_CRU_TOKEN);

        this.longLabel = title;
        this.shortLabel = title;
        this.premierCruLabel = null;
        if (subtitleStartIndex >= 0) {
            this.shortLabel = title.substring(0, subtitleStartIndex - 1).trim();
            this.premierCruLabel = title.substring(subtitleStartIndex).trim();
        }
    }

    public isPremierCru(): boolean {
        return this.premierCruLabel !== null;
    }

    public getDynamicLabel(): string {
        if (this.premierCruLabel !== null)
            return this.premierCruLabel;
        return this.longLabel;
    }
}