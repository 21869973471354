import React from "react";
import { ThemeFactory } from "@chweb/commonui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { WineIdAndTitle } from "../queries/get-wines";

type WineCheckEvent = { id: string, checked: boolean };
type WineCheckOnChangeCallback = (event: WineCheckEvent) => void;

interface WineCheckProps {
  id: string,
  title: string,
  onChange: WineCheckOnChangeCallback,
  selected: boolean,
  intermediate: boolean,
  styleClass?: string
}

class WineCheck extends React.Component<WineCheckProps> {
  private onChange: WineCheckOnChangeCallback;

  constructor (props: WineCheckProps) {
    super(props);
    this.onCheck = this.onCheck.bind(this);
    this.onChange = this.props.onChange;
  }

  private onCheck(): void {
    this.onChange({
      id: this.props.id,
      checked: !this.props.selected
    });
  }

  render () {
    const { title, styleClass } = this.props;
    return (
      <span className = "w3-mobile w3-margin-left" style = {{ cursor: 'pointer' }} >
        <FontAwesomeIcon
          className = { 'w3-xlarge'.concat(this.props.intermediate ? ' w3-opacity' : '') }
          icon = { (this.props.selected || this.props.intermediate) ? faCheckSquare : faSquare }
          onClick = { this.onCheck }
        />&nbsp;
        <span onClick = { this.onCheck } >
          <span className= { 'cptitlesmall ' + styleClass } title = { title }>
            { title }
          </span>
        </span>
      </span>
    );
  }
}


interface WineFilterProps {
  selected: string[],
  filterKey: string,
  wines: WineIdAndTitle[],
  onChange: (filters: {[key: string]: string[]}) => void
}

interface WineFilterState {
  ids: string[],
  defaultAllSelected?: boolean
}

class WineFilter extends React.Component<WineFilterProps, WineFilterState> {
  constructor (props:WineFilterProps) {
    super(props);

    const selectedIds = this.props.selected.slice();
    if (this.isAllSelected(selectedIds)) {
      this.state = {
        ids: [],
        defaultAllSelected: true
      };
    } else {
      this.state = {
        ids: this.props.selected
      };
    }

    this.onChange = this.onChange.bind(this);
  }

  private isAllSelected (selected: string[]): boolean {
    const ids = this.props.wines.map(w => w.id);
    ids.sort();
    const selectedCopy = selected.sort();
    selectedCopy.sort();

    return JSON.stringify(selected) === JSON.stringify(ids);
  }

  private onChangeCallback (): void {
    if (this.props.hasOwnProperty('onChange')) {
      const result: {[key: string]: string[]} = {};
      if (this.state.ids.length === 0) {
        result[this.props.filterKey] = this.props.wines.map(w => w.id);
      } else {
        result[this.props.filterKey] = this.state.ids;
      }
      this.props.onChange(result);
    }
  }

  private onChange (data: WineCheckEvent): void {
    if (data.checked && !this.state.ids.includes(data.id)) {
      let newSelection = this.state.ids.concat(data.id);
      if (this.isAllSelected(newSelection)) {
        newSelection = [];
      }
      this.setState({
        ids: newSelection,
        defaultAllSelected: newSelection.length === 0
      }, this.onChangeCallback);
    }

    if (!data.checked && this.state.ids.includes(data.id)) {
      const newSelection = this.state.ids.filter(id => id !== data.id);
      this.setState({
        ids: newSelection,
        defaultAllSelected: newSelection.length === 0
      }, this.onChangeCallback);
    }
  }

  public reset (): void {
    this.setState({
      ids: this.props.wines.map(w => w.id)
    }, this.onChangeCallback);
  }

  public isWineSelected (id: string): boolean {
    return this.state.ids.some(i => i === id);
  }

  render () {
    const wines = this.props.wines;
    return (
      <div className = "w3-mobile w3-padding-small w3-margin-left">
        {
          wines.map(w => {
            const theme = ThemeFactory.make(w.id);
            return <WineCheck
              key = { w.id }
              id = { w.id }
              title = { w.title }
              styleClass = { theme.header() }
              selected = { this.isWineSelected(w.id) }
              onChange = { this.onChange }
              intermediate = { !!this.state.defaultAllSelected }
            />;
          })
        }
      </div>
    );
  }
}

export default WineFilter;
