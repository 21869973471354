import React from "react";

import { Link } from "gatsby";

import { CarouselData } from "@chweb/commonui";

interface PaginationProps {
  total: number;
  active: number;
  baseUrl?: string;
  visibleCount?: number,
  onChange?: (pageId: number, relative: number) => void;
}

class Pagination extends React.Component<PaginationProps> {
  public static readonly IGNORE_PARAM = -1;

  private visibleCount: number | null;
  constructor (props: PaginationProps) {
    super(props);

    this.visibleCount = null;
    if (this.props.visibleCount !== undefined) {
      this.visibleCount = this.props.visibleCount;
    }

    this.onPageClick = this.onPageClick.bind(this);
  }

  private onPageClick (pageId: number, relative = 0): void {
    if (this.props.onChange) {
      this.props.onChange(pageId, relative);
    }
  }

  render () {
    let leftBound = 0;
    let rightBound = this.props.total - 1;

    if (this.visibleCount) {
      const c = new CarouselData<{idx: number}>(this.visibleCount, true);
      Array.from({
        length: this.props.total
      }).forEach((_, i) => c.addItem({ idx: i }));

      c.moveToIndex(this.props.active);

      const visibles = c.visible();
      leftBound = visibles[0].value.idx;
      rightBound = visibles[visibles.length - 1].value.idx;
    }

    const classNames = ['w3-button', 'cpcolor-hover-brown'];
    const items = [];
    for (let i = leftBound; i <= rightBound; ++i) {
      let classNameProp = undefined;
      if (i === this.props.active) {
        classNameProp = classNames.concat('cpcolor-brown1').join(' ');
      } else {
        classNameProp = classNames.join(' ');
      }

      if (this.props.baseUrl) {
        const link = i === 0 ? `${this.props.baseUrl}` : `${this.props.baseUrl}/${i + 1}`;
        items.push((
          <Link
            to = { link }
            key = { i.toString() }
            className = { classNameProp }
            onClick = { () => this.onPageClick(i) }
          >
            { (i + 1).toString() }
          </Link>
        ));
      } else {
        items.push((
          <span
            key = { i.toString() }
            className = { classNameProp }
            onClick = { () => this.onPageClick(i) }
          >
            { (i + 1).toString() }
          </span>
        ));
      }
    }

    let prevUrlWidget;
    let nextUrlWidget;

    if (this.props.baseUrl) {
      const nextUrl = `${this.props.baseUrl}/${this.props.active + 1 + 1}`;
      let prevUrl = null;
      if (this.props.active === 1) {
        prevUrl = `${this.props.baseUrl}`;
      } else {
        prevUrl = `${this.props.baseUrl}/${this.props.active + 1 - 1}`;
      }

      prevUrlWidget = <Link
        to = { prevUrl }
        className = "w3-button cpcolor-hover-brown"
        onClick = { () => this.onPageClick(Pagination.IGNORE_PARAM, -1) }
      >&laquo;</Link>;

      nextUrlWidget = <Link
        to = { nextUrl }
        className = "w3-button cpcolor-hover-brown"
        onClick = { () => this.onPageClick(Pagination.IGNORE_PARAM, 1) }
      >&raquo;</Link>;
    } else {
      prevUrlWidget = <span
        className = "w3-button cpcolor-hover-brown"
        onClick = { () => this.onPageClick(Pagination.IGNORE_PARAM, -1) }
      >&laquo;</span>;

      nextUrlWidget = <span
        className = "w3-button cpcolor-hover-brown"
        onClick = { () => this.onPageClick(Pagination.IGNORE_PARAM, 1) }
      >&raquo;</span>;
    }

    return (
      <div className = "cpcolor-text-brown" style = {{ clear: 'both' }}>
        <div className = "w3-bar w3-center ">
          { this.props.active === 0 ? null : prevUrlWidget }
          { items }
          { this.props.active === this.props.total - 1 ? null : nextUrlWidget }
        </div>
      </div>
    );
  }
}


export default Pagination;
