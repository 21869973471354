import React, { forwardRef, ReactElement } from 'react';

import { graphql } from 'gatsby';
import queryString from 'query-string';

import Layout from '../components/layout';
import { InterractiveSection } from "@chweb/commonui";

import FilteredSection, { FilteredSectionHanlders, FilteredSectionProps, FilterElementWithReset } from '../components/filtered-section';
import FilterOptions, { FilterResetOption } from '../components/filter-options';
import WineFilter from '../components/wine-filter';
import { Award, AwardProps } from '../components/award';

import {
  parseLabelsFromNodesLocalized as parseLabels,
  getLocalizedValue,
  LocalizedLabels
} from '../components/locale-context';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import getAwards from '../queries/get-awards';
import getWines, { WineIdAndTitle } from '../queries/get-wines';
import { PageInfoData } from '../queries/fragment-page-info';
import { AllLocalesLabels } from '../queries/fragment-all-locales-labels';
import moment from 'moment';

const AWARDS_PER_PAGE = 10;

const YEAR_FILTERS = [
  { label: `${(new Date()).getFullYear()} - 2001`, id: '0', from: 2001, till: null },
  { label: '2000 - 1976', id: '1', from: 1976, till: 2000 },
  { label: '1975 - 1925', id: '2', from: 1925, till: 1975 }
];

interface FilterValue {
  wineIds: string[],
  years: string[]
}

interface AwardFilterProps {
  labels: LocalizedLabels,
  wines: WineIdAndTitle[],
  wakeupValue: {
    wineIds: string[]
  },
  onChange: (filterValue: Partial<FilterValue>) => void 
}

type AwardFilterState = FilterValue;

class AwardFilter extends React.Component<AwardFilterProps, AwardFilterState> implements FilterElementWithReset{
  private labels: LocalizedLabels;
  private wineFilterRef: React.RefObject<WineFilter>;
  private yearFilterRef: React.RefObject<FilterOptions>;
  private yearOptions: typeof YEAR_FILTERS;

  constructor (props: AwardFilterProps) {
    super(props);

    this.labels = this.props.labels;

    this.onChange = this.onChange.bind(this);

    this.wineFilterRef = React.createRef<WineFilter>();
    this.yearFilterRef = React.createRef<FilterOptions>();

    this.yearOptions = YEAR_FILTERS;

    this.state = {
      wineIds: this.props.wakeupValue.wineIds,
      years: this.yearOptions.map((_, i) => i.toString())
    };
  }

  private onChange (filterValue: Partial<FilterValue>): void {
    const newState = Object.assign(this.state, filterValue);
    this.setState(newState, () => this.props.onChange(this.state));
  }

  public reset (): void {
    if (this.wineFilterRef.current)
      this.wineFilterRef.current.reset();
    if (this.yearFilterRef.current)
      this.yearFilterRef.current.reset(FilterResetOption.NONE_SELECTED);
  }

  render () {
    return (
      <div>
        <WineFilter
          wines = { this.props.wines }
          selected = { this.props.wakeupValue.wineIds }
          filterKey = { 'wineIds' }
          onChange = { this.onChange }
          ref = { this.wineFilterRef }
        />
        <FilterOptions
          label = { this.labels.awardsFiltersYearsTitle }
          multiselect
          options = { this.yearOptions }
          selected = { [] }
          filterKey = { 'years' }
          onChange = { this.onChange }
          ref = { this.yearFilterRef }
        />
      </div>
    );
  }
}

interface AwardsListProps {
  data: PageAwardsData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}
  
type FilteredSectionSpecifiedProps = FilteredSectionProps<AwardFilter, FilterValue>;
const FilteredSectionForwarded = forwardRef<FilteredSectionHanlders, FilteredSectionSpecifiedProps>(FilteredSection);

export default function AwardsList ({ data, location, pageContext }: AwardsListProps): ReactElement {
  const { locale } = pageContext;
  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);

  const seo = PageSEO.fromLocation(location);
  updateSEO(seo, localPageInfo);

  const wines = getWines(locale, true);
  const labels = parseLabels(data.labels.nodes, locale);
  const awards = getAwards();

  const getInitialFilter = () => {
    let allowed = wines.map(w => w.id);
    if (location && location.search) {
      const params = queryString.parse(location.search);
      const wineId = params.wine as string;
      if (params.wine && allowed.includes(wineId)) {
        allowed = [wineId];
      }
    }
    return {
      wineIds: allowed,
      years: []
    };
  };

  const renderFilters = (onChange: (filterValue: Partial<FilterValue>) => void,  widgetRef: React.RefObject<AwardFilter>) => <AwardFilter
    onChange = { onChange }
    ref = { widgetRef }
    wines = { wines }
    wakeupValue = { getInitialFilter() }
    labels = { labels }
  />;

  const filterMethod = (filter: Partial<FilterValue>, child: React.ReactNode) => {
    const childAward = child as ReactElement<AwardProps>;
    if (filter.wineIds && filter.wineIds.includes(childAward.props.wine.id)) {
      if (!Array.isArray(filter.years) || filter.years.length === 0) {
        return true;
      }
      const filterYears = filter.years || [];
      const activeYears = YEAR_FILTERS.filter(y => filterYears.includes(y.id));
      const testYear = (childAward.props.when as moment.Moment).toDate().getFullYear();
      return activeYears.some(
        a => testYear >= a.from && (!a.till || testYear <= a.till));
    }
    return false;
  };

  const renderWrapper = (children: React.ReactNode[]) => <div>{ children }</div>;
  
  return (
    <Layout locale={locale} location={location} title = { localPageInfo.title } seo = { seo } >
      <InterractiveSection title = { localPageInfo.title } >
        <FilteredSectionForwarded
          renderResultsMethod = { renderWrapper }
          filtersRenderMethod = { renderFilters }
          filterAppliesMethod = { filterMethod }
          emptyMessage = { labels.awardsFiltersNoResults }
          perpage = { AWARDS_PER_PAGE }
        >
          {
            awards.map((a, i) => <Award
              wine = { wines.find(w => w.id === a.awarded) as WineIdAndTitle }
              key = { i.toString() }
              { ...a }
            />)
          }
        </FilteredSectionForwarded>
      </InterractiveSection>
    </Layout>
  );
}

interface PageAwardsData {
  page: PageInfoData,
  labels: {
    nodes: AllLocalesLabels[]
  }
}

export const query = graphql`{
  page: pageIndexYaml(yamlId : {eq: "awards"}) {
    ...PageInfo
  }
  labels: allLabelsTranslatedYaml(filter: {name: {in: [
    "awardsFiltersNoResults",
    "awardsFiltersYearsTitle",
    "awardsFiltersPresentLabel"
  ]}}) {
    nodes {
      ...AllLocalesLabels
    }
  }
}`;
