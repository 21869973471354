import { useStaticQuery, graphql } from 'gatsby';

import {
  LocalizedLabels,
  parseLabelsFromNodesLocalized as parseLabels
} from '../components/locale-context';

export default function getAwardLabels (locale: string): LocalizedLabels {
  const { labels } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "awardsMedalGold",
        "awardsMedalSilver",
        "awardsMedalBronze",
        "awardsPublishedLabel",
        "awardsVintage"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
    }`
  );
  return parseLabels(labels.nodes, locale);
}
