import { useStaticQuery, graphql } from 'gatsby';
import { reduceToLocale } from '../libs/locale';
import { WineTitleInfo } from '../libs/wine-title';
import { getWineLocalTitle } from '../queries/page-wine-helpers';
import { WineListData, WineListLocalized } from './fragment-wine-list';

export interface WineIdAndTitle {
  id: string,
  title: string
}

interface WineBasicData {
  wines: {
    frontmatter: {
      wines: WineListData[]
    }
  }
}

export default function getWines (locale: string, useShortTitle = false): WineIdAndTitle[] {
  const { wines }: WineBasicData = useStaticQuery(
    graphql`query ($includeBottle:Boolean = false, $includeDescriptions:Boolean = false) {
      wines: markdownRemark(fields: {slug: {eq: "/wines-list/"}}) {
        frontmatter {
          wines {
            ...wineList
          }
        }
      }
    }`
  );

  const winesLocalized = wines.frontmatter.wines.map(w => reduceToLocale<unknown,unknown,WineListLocalized & {id: string}>(w as any, locale));
  return winesLocalized.map(w => {
    const wineTitle = new WineTitleInfo(getWineLocalTitle(w.frontmatter.pageId.pageTitle, locale));
    return {
      id: w.id,
      title: useShortTitle ? wineTitle.getDynamicLabel() : wineTitle.longLabel
    };
  });
}
