import React, { PropsWithChildren } from 'react';

import {
  faAngleDoubleDown,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CollapsibleProps = PropsWithChildren<{
  className?: string,
  collapseLabel?: string,
  expandLabel?: string
}>;

interface CollapsibleStats {
  visible: boolean
}

class Collapsible extends React.Component<CollapsibleProps, CollapsibleStats> {
  constructor (props: CollapsibleProps) {
    super(props);

    this.state = {
      visible: true
    };
    this.onToggle = this.onToggle.bind(this);
  }

  private onToggle (): void {
    this.setState({
      visible: !this.state.visible
    });
  }

  render () {
    return (
      <div className = { this.props.className } >
        <div
          className = "w3-panel"
          style = {{
            cursor: 'pointer'
          }}
          onClick = { this.onToggle }
        >
          { this.state.visible
            ? <span><FontAwesomeIcon icon = { faAngleDoubleDown } /> { this.props.collapseLabel }</span>
            : <span><FontAwesomeIcon icon = { faAngleDoubleRight } /> { this.props.expandLabel }</span>
          }
        </div>
        {
          this.state.visible
            ? <div>{ this.props.children }</div>
            : null
        }
      </div>
    );
  }
}

export default Collapsible;
