import React, {
  useState,
  useRef,
  useContext,
  useImperativeHandle,
  ReactElement,
  PropsWithChildren,
  ForwardedRef
} from 'react';

import {
  useDidUpdateEffect
} from '../hooks/did-update';

import Collapsible from '../components/collapsible-panel';
import Pagination from '../components/pagination';

import { LocaleContext, getLocalizedValue } from '../components/locale-context';
import { getFilterLabels } from '../queries/get-filter-section-labels';

import { scrollToAnchor } from "@chweb/commonui";

interface EmptyWarningProps {
  message: string,
  onReset: () => void
}

function EmptyWarning ({ message, onReset }: EmptyWarningProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const labels = getFilterLabels();
  const localLabels = getLocalizedValue(labels, locale);

  return (
    <p className = "cpcolor-text-brown">{ message }&nbsp;
      <span
        className = "w3-border-bottom cpcolor-border-brown"
        title = { localLabels.filteredSectionResetFiltersTooltip }
        style = {{ cursor: 'pointer' }}
        onClick = { onReset }
      >
        { localLabels.filteredSectionResetFilters }
      </span>
    </p>
  );
}

const DEFAULT_PER_PAGE = 5;

function getPageCount (visibleCount: number, perPage: number): number {
  return Math.ceil(visibleCount / perPage);
}

export type FilteredSectionProps<FilterElement extends FilterElementWithReset, FilterValue> = PropsWithChildren<{
  renderResultsMethod?: (elements: React.ReactNode[]) => JSX.Element,
  filtersRenderMethod: (onChangeCallback: (currentFilter: Partial<FilterValue>) => void, refWidget: React.RefObject<FilterElement>) => JSX.Element,
  filterAppliesMethod: (filterValue: FilterValue, elementToCheck: React.ReactNode) => boolean,
  perpage?: number,
  emptyMessage: string
}>

export type FilteredSectionHanlders = {
  update: () => void
}

export interface FilterElementWithReset {
  reset: () => void
}

const FilteredSection = <FilterElement extends FilterElementWithReset, FilterValue extends object>({
    children,
    renderResultsMethod,
    filtersRenderMethod,
    filterAppliesMethod,
    perpage,
    emptyMessage
  }: FilteredSectionProps<FilterElement, FilterValue>, ref: ForwardedRef<FilteredSectionHanlders>) => {

  const PER_PAGE = perpage || DEFAULT_PER_PAGE;

  const [currentPage, setCurrentPage] = useState(0);
  useDidUpdateEffect(() => {
    scrollToAnchor('filters');
  }, [currentPage]);

  // local handlers
  function onFilterChange (filterValue: Partial<FilterValue>): void {
    setFilter(filterValue);
    setCurrentPage(0);
    scrollToAnchor('filters');
  }

  function onPageChange (newPageIdx: number, relativeChange: number) {
    if (newPageIdx !== -1) {
      setCurrentPage(newPageIdx);
    } else {
      setCurrentPage(currentPage + relativeChange);
    }
    scrollToAnchor('filters');
  }

  function applyFilter (filterValue: FilterValue, data: React.ReactNode[]): React.ReactNode[] {
    if (!filterValue) {
      return data;
    }
    return data.filter(d => filterAppliesMethod(filterValue, d));
  }

  const filterWidgetsRef = useRef<FilterElement>(null);
  const filterWidgets = filtersRenderMethod((filterValue: Partial<FilterValue>) => onFilterChange(filterValue), filterWidgetsRef);

  const wakeupFilter = filterWidgets.props.wakeupValue || null;
  const [ filter, setFilter ] = useState(wakeupFilter);

  useImperativeHandle(ref, () => ({
    update () {
      onFilterChange(filter);
    }
  }));

  // always working on a full list of children
  const allAvailableItems = React.Children.toArray(children) as React.ReactNode[];
  // computing what is visible and how
  const visibles = applyFilter(filter, allAvailableItems);
  const pageCount = getPageCount(visibles.length, PER_PAGE);
  const pageItems = visibles.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE);

  // labguage and label context
  const { locale } = useContext(LocaleContext);
  const labels = getFilterLabels();
  const localLabels = getLocalizedValue(labels, locale);

  return (
      <>
        <div id = { 'filters' } className = "w3-panel w3-padding-16 cpcolor-text-brown">
          <Collapsible
            collapseLabel = { localLabels.filteredSectionHideFilters }
            expandLabel = { localLabels.filteredSectionShowFilters }
          >
            { filterWidgets }
          </Collapsible>
        </div>
        <div className = "w3-panel w3-padding-16">
          { renderResultsMethod ? renderResultsMethod(pageItems) : pageItems }
        </div>
        <div className = "w3-panel w3-padding-16 cpcolor-text-brown">
          { pageCount > 1
            ? <Pagination
              total = { pageCount }
              visibleCount = { 10 }
              active = { currentPage }
              onChange = { (newPageIdx, relativeChange) =>
                onPageChange(newPageIdx, relativeChange) }
            />
            : (pageCount === 1
              ? null
              : <EmptyWarning
                message = { emptyMessage }
              onReset={() => {
                if (filterWidgetsRef.current)
                  filterWidgetsRef.current.reset();
              }}/>)
          }
        </div>
      </>
  );
};

export default FilteredSection;
