import React, {
  useContext,
  ReactElement,
  MouseEventHandler
} from 'react';

import { LocaleContext, getLocalizedValue, LocalizedLabels } from '../components/locale-context';
import { getFilterLabels } from '../queries/get-filter-widgets-labels';

type FilterOptionId = string;

export type FilterOptionData = {
  id: FilterOptionId;
  label?: string;
  labelId?: string;
};

type FilterOptionProps = {
  data: FilterOptionData;
  isSelected: boolean;
  onClick: MouseEventHandler;
};

function FilterOption ({ data, isSelected, onClick }: FilterOptionProps): ReactElement {
  const baseClass = 'w3-bar-item w3-mobile w3-border w3-hover-white cpcolor-border-brown1';
  const extraClass = isSelected
    ? ' cpcolor-brown1 cpcolor-hover-brown'
    : ' cpcolor-hover-border-brown';

  const { locale } = useContext(LocaleContext);
  const labels = getFilterLabels();
  const localLabels = getLocalizedValue<LocalizedLabels>(labels, locale);

  return (
    <div
      className = { baseClass.concat(extraClass) }
      onClick = { onClick }
      style = {{ cursor: 'pointer' }}
    >
      { data.label ? data.label : ( data.labelId ? localLabels[data.labelId] : '') }
    </div>
  );
}

type FilterSelection = { [key: string]: FilterOptionId[] };
type FilterChangeCallback = (state: FilterSelection) => void;

type FilterOptionsProps = {
  label: string;
  filterKey: string;
  options: FilterOptionData[];
  selected: FilterOptionId[];
  multiselect?: boolean;
  onChange: FilterChangeCallback;
  className?: string;
  extraWidgets?: ReactElement;
};

type FilterOptionsState = {
  selected: FilterOptionId[]
};

export const FilterResetOption = {
  NONE_SELECTED: 0,
  ALL_SELECTED: 1,
  DEFAULT_SELECTED: 2
} as const;
type FilterResetOption = typeof FilterResetOption[keyof typeof FilterResetOption];

class FilterOptions extends React.Component<FilterOptionsProps, FilterOptionsState> {
  private multiSelect: boolean;

  constructor (props: FilterOptionsProps) {
    super(props);

    const selected = this.props.selected || [];
    this.state = {
      selected: selected
    };
    this.multiSelect = !!this.props.multiselect;
  }

  private onClickOption (id: FilterOptionId): void {
    if (this.multiSelect) {
      if (this.state.selected.includes(id)) {
        this.setState({
          selected: this.state.selected.filter(s => s !== id)
        }, this.postChanges);
      } else {
        this.setState({
          selected: this.state.selected.concat(id)
        }, this.postChanges);
      }
    } else {
      this.setState({
        selected: [id]
      }, this.postChanges);
    }
  }

  private postChanges (): void {
    const result: FilterSelection = {};
    result[this.props.filterKey] = this.state.selected;
    this.props.onChange(result);
  }

  public reset (option: FilterResetOption): void {
    let selected: FilterOptionId[] = []; // NONE
    switch (option) {
      case FilterResetOption.ALL_SELECTED:
        selected = this.props.options.map(o => o.id);
        break;
      case FilterResetOption.DEFAULT_SELECTED:
        selected = this.props.selected || [];
        break;
      default:
    }

    this.setState({
      selected: selected
    }, this.postChanges);
  }

  render () {
    const wrappingClass = this.props.className
      ? `w3-bar w3-padding ${this.props.className}`
      : `w3-bar w3-padding`;

    return (
      <div className = { wrappingClass } >
        <div className = "w3-bar-item w3-container w3-mobile">
          <span>{ this.props.label }</span>
        </div>
        {
          this.props.options.map(o =>
            <FilterOption
              data = { o }
              key = { o.id }
              isSelected = { this.state.selected.includes(o.id) }
              onClick = { () => this.onClickOption(o.id) }
            />
          )
        }
        { this.props.extraWidgets ? this.props.extraWidgets : null }
      </div>
    );
  }
}

export default FilterOptions;
