import { useStaticQuery, graphql } from 'gatsby';
import { labelsByLocale } from '../components/locale-context';

export const getFilterLabels = () => {
  const { labels } = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
          "filteredSectionHideFilters",
          "filteredSectionShowFilters",
          "filteredSectionResetFilters",
          "filteredSectionResetFiltersTooltip",
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
    }`
  );

  return labelsByLocale(labels.nodes);
}
